/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */

import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import 'select2';
import $ from 'jquery';

const toggleInvalidClass = (elementOrClass, valid) => {
  let finalElement = elementOrClass;

  // Not a string: this method sometimes receives a string (a class) that will be used
  // to locate elements using jQuery.
  // Is this a hidden component which uses select2 ?
  if (typeof elementOrClass !== 'string' && elementOrClass.classList.contains('select2-hidden-accessible')) {
    // Get the next element which happens to be the select2 component, and we need to apply the
    // invalid class to that one.
    finalElement = elementOrClass.nextElementSibling;
  }

  if (valid) {
    $(finalElement).addClass('invalid');
  } else {
    $(finalElement).removeClass('invalid');
  }
};

const toggleRequiredText = (element, valid) => {
  let finalElement = element;

  // Is this a select 2 component ?
  if (element.classList && element.classList.contains('select2')) {
    // Get the next sibling since that's the one with the error message
    finalElement = element.nextElementSibling;
  }

  if (valid) {
    $(finalElement).removeClass('hidden');
  } else {
    $(finalElement).addClass('hidden');
  }
};

export default class extends Controller {
  static targets = [
    'modal', 'searchableRow', 'deleteModal', 'deleteRecordButton', 'cancelModal', 'cancelClinicModal',
    'cancelRecordButton', 'updateEventModal', 'deletePastClinicModal', 'showClinicActivityPageButton',
    'requiredField', 'vaccinationCheckBox', 'restoreModal', 'restoreRecordButton', 'dateFlickrField',
    'select2PluginField',
  ];

  static values = { beta20240925prm2549: Boolean };

  connect() {
    // This is a new class being used in this view to identify the select2 fields. The existing
    // field which is shown when the allow_multiple_vaccines_in_encounter_box is off, uses the
    // clinic-staff-name class. And that one is initialized in the bind_select2.js file.
    $('.select2-field').select2({
      width: '100%',
    });

    const that = this;
    $('.select2-field').on('select2:select', (e) => that.validateRequiredFields(e));
  }

  addValidation(event) {
    if (event.currentTarget.checked) {
      $(event.target).addClass('changed-check');
      // eslint-disable-next-line array-callback-return
      this.requiredFieldTargets.filter((elem) => {
        if (elem.dataset.childIndex !== event.currentTarget.dataset.childIndex) { return; }
        if (elem.value !== '') { toggleInvalidClass(elem, false); return; }
        toggleInvalidClass(elem, true);
        $(elem).addClass('required-select');
        toggleRequiredText(elem.nextElementSibling, true);
      });
    } else {
      $(event.target).removeClass('changed-check');
      // eslint-disable-next-line array-callback-return
      this.requiredFieldTargets.filter((elem) => {
        if (elem.dataset.childIndex !== event.currentTarget.dataset.childIndex) { return; }
        toggleInvalidClass(elem, false);
        toggleRequiredText(elem.nextElementSibling, false);
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  validateRequiredFields(event) {
    if (event.target.value === '') {
      $(event.target).addClass('required-select');
      toggleInvalidClass(event.target, true);
      toggleRequiredText(event.target.nextElementSibling, true);
    } else {
      $(event.target).removeClass('required-select');
      toggleInvalidClass(event.target, false);
      toggleRequiredText(event.target.nextElementSibling, false);
    }
  }

  validateDoseVolume(event) {
    const element = event.currentTarget;
    if (parseFloat(element.value) < 0) {
      alert('Volume administered cannot be less than zero');
      element.value = element.dataset.previousValue;
      if (this.requiredFieldTargets.includes(element)) {
        this.validateRequiredFields(event);
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  selectAllCheckboxes() {
    if ($('#_select_all').prop('checked')) {
      $('#patient-info').show();
    } else {
      $('#patient-info').hide();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  selectAllRecords() {
    $('#all_selection').val('true');
    $('#patient-info').hide();
    $('#all-selection').show();
  }

  // eslint-disable-next-line class-methods-use-this
  clearSelection() {
    $('#all_selection').val('false');
    $('#all-selection').hide();
    $('#_select_all').trigger('click');
  }

  // eslint-disable-next-line class-methods-use-this
  showCategoryConfirmationModal(event) {
    const { dataset } = event.target;
    const clinicClosed = dataset.clinicClosed === 'true';
    const currentCategorySelected = dataset.selection === dataset.currentCategory;

    if (clinicClosed || currentCategorySelected) {
      return;
    }

    $('#submit-category-change').attr('data-patient-id', dataset.patientId);
    $('#cancel-category-change').attr('data-patient-id', dataset.patientId);
    $('#cancel-category-change').attr('data-current-category', dataset.currentCategory);
    $('#cancel-category-change').attr('data-selection', dataset.selection);
    $('.category-confirmation-modal').toggleClass('hidden');
  }

  // eslint-disable-next-line class-methods-use-this
  cancelCategoryChange(event) {
    const { dataset } = event.target;
    const { patientId } = dataset;
    const { currentCategory } = dataset;
    const { selection } = dataset;

    if (currentCategory) {
      $(`#patient_${patientId}_clinic_event #clinic_event_category_${currentCategory.toLowerCase()}`).prop('checked', true);
    } else {
      $(`#patient_${patientId}_clinic_event #clinic_event_category_${selection.toLowerCase()}`).prop('checked', false);
    }

    $('#cancel-category-change').attr('data-patient-id', null);
    $('#cancel-category-change').attr('data-current-category', null);
    $('#cancel-category-change').attr('data-selection', null);
    $('.category-confirmation-modal').toggleClass('hidden');
  }

  // eslint-disable-next-line class-methods-use-this
  submitCategoryChange(event) {
    const formClass = `clinic_event_${event.target.dataset.patientId}_form`;

    if (this.beta20240925prm2549Value) {
      const closestForm = document.getElementsByClassName(formClass)[0];
      closestForm.submit();
    } else {
      Rails.fire($(`.${formClass}`)[0], 'submit');
    }
  }

  showRemarksModal(event) {
    const isClinicClosed = event.currentTarget.dataset.clinicclosed === 'true';
    if (isClinicClosed) {
      event.preventDefault();
      this.cancelClinicModalTarget.classList.toggle('hidden');
      return;
    }
    const modals = this.modalTargets;
    const { patientId } = event.target.closest('tr').dataset;

    const remarkModals = modals.filter((modal) => modal.dataset.categorySelection === 'Remark');

    const [chosenPatientRemarkModal] = remarkModals.filter(
      (modal) => modal.dataset.patientId === patientId,
    );

    $('.update-remark-action').attr('data-action', $(event.target).data('next-target'));

    chosenPatientRemarkModal.classList.toggle('hidden');
  }

  showModal(event) {
    if (event.currentTarget.dataset.clinicclosed === 'true') {
      event.preventDefault();
      $('.cancel-clinic-modal').toggleClass('hidden');
      return;
    }
    const categorySelection = event.target.dataset.selection;
    const modals = this.modalTargets;
    let modal = '';

    if (categorySelection === 'AddPatient') {
      modal = document.getElementById('add_patient_modal');
    } else {
      [modal] = modals.filter((el) => {
        const { patientId } = event.target.closest('tr').dataset;
        const patientIdsMatch = el.dataset.patientId === patientId;
        const categorySelectionsMatch = el.dataset.categorySelection === categorySelection;

        return (patientIdsMatch && categorySelectionsMatch);
      });
    }

    if (categorySelection === 'Remark') {
      $('.update-remark-action').attr('data-action', $(event.target).data('next-target'));
    }

    modal.classList.toggle('hidden');

    if (event.target.nodeName !== 'INPUT' || event.target.dataset.sustainChecked === 'true') { return; }
    event.target.checked = false;
  }

  // eslint-disable-next-line class-methods-use-this
  okModal(event) {
    event.target.closest('tr').setAttribute('data-event-category', event.target.dataset.eventCategory);
    event.target.closest('.modal').classList.toggle('hidden');
  }

  // eslint-disable-next-line class-methods-use-this
  cancelModal(event) {
    // Only the vaccine encounter box form will be reset
    if (event.target.dataset.eventCategory === 'Vaccinated') {
      const patientId = event.target.dataset.parent;
      // Get the form corresponding to this event
      const form = document.getElementsByClassName(`clinic_event_${patientId}_form`)[0];
      this.resetForm(form);
    }

    event.target.closest('.modal').classList.toggle('hidden');
  }

  updatePatientTable(event) {
    event.preventDefault();

    let isValidForm = true;
    let showAlert = false;
    const that = this;

    this.vaccinationCheckBoxTargets.forEach((checkField) => {
      if (checkField.checked === true && checkField.classList.contains('changed-check')) {
        that.requiredFieldTargets.forEach((inputField) => {
          const inputIsRequired = inputField.required && inputField.classList.contains('required-select');
          const childIndexesMatch = inputField.dataset.childIndex === checkField.dataset.childIndex;
          const patientIdsMatch = checkField.dataset.patientId === inputField.dataset.patientId;

          if (inputIsRequired && childIndexesMatch && patientIdsMatch) {
            isValidForm = inputField.value !== '';
            if (!isValidForm) {
              showAlert = true;
            }
          }
        });
      }
    });

    this.requiredFieldTargets.forEach((inputField) => {
      if (!inputField.closest('fieldset')?.disabled && inputField.required && inputField.classList.contains('vaccinated-details') && event.target.closest('tr').dataset.patientId === inputField.dataset.patientId) {
        isValidForm = inputField.value !== '';
        if (!isValidForm) {
          showAlert = true;
        }

        // There's a bug in this screen with the chosen field. The chosen field gets hidden if it
        // has a value. With this guard we prevent hidding it.
        if (!inputField.classList.contains('chosen-select')) {
          toggleInvalidClass(inputField, !isValidForm);
          toggleRequiredText(inputField.nextElementSibling, !isValidForm);
        }
      }
    });

    if (showAlert && event.target.dataset.eventCategory !== 'Remark') {
      $('.confirm-modal').addClass('hidden');
      alert('Please fill out required fields.');
    } else if ((event.target.dataset.eventCategory !== 'Remark') && ($(`.vaccine-check-${event.target.dataset.clinicEvent}:checked`).length === 0)
      && ($(`.vaccine-check-${event.target.dataset.clinicEvent}`).length !== 0)) {
      $('.confirm-modal').addClass('hidden');
      toggleInvalidClass(`.vaccine-check-${event.target.dataset.clinicEvent}`, true);

      alert('Must select at least one vaccine.');
    } else {
      toggleInvalidClass(`.vaccine-check-${event.target.dataset.clinicEvent}`, false);

      const radioButton = [...event.target.closest('tr').getElementsByTagName('input')]
        .find((elem) => elem.dataset.selection === event.target.dataset.eventCategory);

      const className = `clinic_event_${event.currentTarget.dataset.clinicEvent}_form`;
      const closestForm = document.getElementsByClassName(className)[0];

      if (radioButton) {
        radioButton.checked = true;
      }

      if (this.beta20240925prm2549Value) {
        closestForm.submit();
      } else {
        Rails.fire(closestForm, 'submit');
      }

      $('.updateEventYesButton').attr('disabled', true);
    }
  }

  search(event) {
    const searchTerm = event.target.value.toLowerCase();
    this.searchableRowTargets.forEach((el) => {
      const filterableVenue = el.getAttribute('data-search-content');

      el.classList.toggle('hidden', !filterableVenue.includes(searchTerm));
    });
  }

  showHideDeleteModal(e) {
    this.deleteRecordButtonTarget.setAttribute('href', `/${e.currentTarget.dataset.recordis}/${e.currentTarget.dataset.recordid}?routeparams=${e.currentTarget.dataset.routeparams}`);

    if (e.currentTarget.dataset.recordis === 'patients') document.getElementsByClassName('recordName')[0].innerHTML = e.currentTarget.dataset.recordname;

    if (e.currentTarget.dataset.clinicclosed === 'false' || e.currentTarget.dataset.clinicclosed === undefined) {
      this.deleteModalTarget.classList.toggle('hidden');
    } else {
      $('.cancel-clinic-modal').toggleClass('hidden');
    }
  }

  onShowHideDeletePastClinicModal(e) {
    this.deletePastClinicModalTarget.classList.toggle('hidden');
    const href = `/clinics/${e.currentTarget.dataset.clinicid}/activity`;
    this.showClinicActivityPageButtonTarget.href = href;
  }

  submitCloseClinicForm() {
    const className = `close_clinic_${this.closeButtonTarget.value}_form`;
    document.getElementsByClassName(className)[0].submit();
  }

  onShowHideCancelModal(event) {
    this.cancelModalTarget.classList.toggle('hidden');
    const href = `/clinics/${event.currentTarget.dataset.cancelid}/archivals`;
    this.cancelRecordButtonTarget.href = href;
  }

  onShowHideRestoreModal(event) {
    this.restoreModalTarget.classList.toggle('hidden');
    const href = `/clinics/${event.currentTarget.dataset.clinicid}/archivals`;
    this.restoreRecordButtonTarget.href = href;
  }

  toggleDeleteModal(event) {
    this.cancelModalTarget.classList.toggle('hidden');
    const href = `/supply_inventories/${event.currentTarget.dataset.cancelid}`;
    this.cancelRecordButtonTarget.href = href;
  }

  toggleCancelClinicModal(event) {
    event.preventDefault();
    this.cancelClinicModalTarget.classList.toggle('hidden');
  }

  // eslint-disable-next-line class-methods-use-this
  cancelClinic(event) {
    event.preventDefault();
    event.currentTarget.disabled = true;

    const form = $('#clinic-form');
    form.append("<input type='hidden' name='save_and_submit' value='true' />");
    form.submit();
  }

  setEmailCancellationOption(event) {
    const url = new URL(this.deleteRecordButtonTarget.href);
    const { searchParams } = url;

    searchParams.set('email_option', event.target.checked);
    url.search = searchParams.toString();

    this.deleteRecordButtonTarget.href = url;
  }

  onClickUpdateEvent(e) {
    e.preventDefault();

    const modals = this.updateEventModalTargets;
    let modal = '';
    const { patientId } = e.target.closest('tr').dataset;
    const { eventCategory } = e.target.dataset;

    [modal] = modals.filter(
      (el) => (el.dataset.patientId === patientId && el.dataset.eventCategory === eventCategory),
    );

    if (eventCategory === 'Tested') {
      $('.modal-text').html("Are you sure you want to update this patient's test?");
      $('.cancel-text').html('Cancel');
    } else if (modal.dataset.clinicVaccine === 'true') {
      $('.modal-text').html('This encounter has already been documented. Are you sure you want to change it?');
      $('.cancel-text').html('Cancel');
    } else {
      $('.modal-text').html('You cannot enter a vaccination encounter without vaccine details for this clinic. Please update clinic vaccine inventory in Edit Clinic.');
      $('.cancel-text').html('Okay');
      $('.submit-button').hide();
    }

    modal.classList.toggle('hidden');
  }

  // eslint-disable-next-line class-methods-use-this
  onSelectClick(event) {
    toggleInvalidClass(`.vaccine-check-${event.target.dataset.patientId}`, false);
  }

  resetForm(form) {
    // Reset dateflickr fields to their default date
    this.dateFlickrFieldTargets.forEach((date) => {
      // eslint-disable-next-line no-underscore-dangle
      date._flatpickr.setDate(date._flatpickr.config.defaultDate);
    });

    // Reset all form's inputs to their original value
    form.reset();

    // Reset clinic staff field to its original value
    const select2UpdatedEvent = new Event('change');
    this.select2PluginFieldTargets.forEach((field) => {
      field.dispatchEvent(select2UpdatedEvent);
    });
  }
}
